// Configure service workers if needed
if(window.navigator && navigator.serviceWorker) {
  navigator.serviceWorker.getRegistrations()
  .then(function(registrations) {
    for(let registration of registrations) {
      registration.unregister();
    }
  });
}

import Alpine from 'alpinejs'

// Remove when not in development
window.Alpine = Alpine

// globals/functions used in app interactivity
const timeToShowFailure = 30000;

// Define common x-data elements
Alpine.data('mainApp', () => ({
  userName: null,
  userEmail: null,
  formSuccess: false,
  formFailure: false,
  get nameWithLeadingSpace() {
    if (this.userName) { return ' ' + this.userName }
    return ''
  },
  get successName() {
    if (!this.userName) {
      return ''
    }

    const nameparts = this.userName.split(' ')
    if (nameparts.length == 2 && nameparts[0]) {
      return ', ' + nameparts[0]
    } else {
      return ', ' + this.userName
    }
  },
  summonCalendly(event) {
    // console.log('summoned!', event)
    const calendlyConfig = {url: 'https://calendly.com/jgoldfar/intro'}
    if (this.userName || this.userEmail) {
      calendlyConfig.prefill = {
        name: this.userName ?? undefined,
        email: this.userEmail ?? undefined
      };
    }
    Calendly.initPopupWidget(calendlyConfig);
    return false;
  },
  submitForm(event) {
    // console.log(event);
    event.preventDefault();
    const formData = new FormData(event.target);
    return fetch("https://usebasin.com/f/c5e3a00dbed9", {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: formData,
    })
      .then((response) => {
        if (response.status === 200) {
          this.formSuccess=true
        } else {
          this.formFailure=true
          setTimeout(() => {this.formFailure=false;}, timeToShowFailure);
        }
      })
      .catch((error) => {
        this.formFailure=true;
        console.log(error);
        setTimeout(() => {this.formFailure=false;}, timeToShowFailure);
      });
  }
}))

Alpine.start()
